<template>
  <div class="myPromotion">
    <mt-header :title="$t('myPromotion')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div v-if="!list.length" class="listNull">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconzanwuxiaoxi-" />
        </svg>
        <div class="tips">{{ $t('noInviteFriends') }}</div>
      </div>
      <template v-else>
        <div v-for="(item, index) in list" :key="index" class="wrap">
          <div>{{ item.phone }}</div>
          <div>{{$t('myPromotionTips')}} {{item.registerDate}}</div>
        </div>
      </template>
      <div class="bottom">
        <mt-button type="primary" size="large" plain @click="$router.push('/mine/inviteFriends')">{{
          $t("inviteFriendsImmediately")
        }}</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import { myRecommend } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
export default {
  name: 'MyPromotion',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getMyRecommend()
      this.$indicator.close();
    },
    async getMyRecommend() {
      await this.$axios({
        method: "get",
        url: myRecommend,
        params: {
          level: '1',
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.list = e.body
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.myPromotion {
  .content {
    padding: 20px;
    .listNull {
      text-align: center;
      .icon {
        font-size: 200px;
        margin: 40px 10px 10px 10px;
      }
      .tips {
        color: #868686;
      }
    }
    .wrap{
      padding-top: 5px;
      padding: 5px;
      border: 1px solid #d6d6d6;
      border-radius: 5px;
      margin: 10px 0px;
      font-size: 14px;
      div {
        &:first-child {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
  .bottom {
    padding: 60px 20px 20px 20px;
  }
}
</style>